import { ReactionModel } from "../models/Post.model";

const REACTION_MAPPING: { [key: string]: string[] } = {};

export enum REACTION_ACTION {
  INCREMENT = 'increment',
  DECREMENT = 'decrement',
}

export class ReactionService {
  static performReactionSideEffect = (
    increment: boolean,
    postId: string,
    reaction: ReactionModel,
    setReaction: React.Dispatch<React.SetStateAction<number>>
  ): void => {
    if (increment) {
      if (!REACTION_MAPPING[postId]) {
        REACTION_MAPPING[postId] = [reaction.reaction];
      } else {
        REACTION_MAPPING[postId].push(reaction.reaction);
      }
      setReaction(++reaction.count);
    } else {
      setReaction(--reaction.count);
      REACTION_MAPPING[postId]?.splice(
        REACTION_MAPPING[postId].indexOf(reaction.reaction),
        1
      );
    }
  };

  static reactionIncrementOrDecrement = (
    postId: string,
    reaction: ReactionModel
  ) => {
    if (REACTION_MAPPING[postId]?.includes(reaction.reaction)) {
      return false;
    } else {
      return true;
    }
  };
}
