import { Box, VStack } from "@chakra-ui/layout";
import React, { ReactElement } from "react";
import { PostModel } from "../../models/Post.model";
import Post from "../Post/Post";

const Posts = ({ posts }: { posts: PostModel[] }): ReactElement => {
  return (
    <VStack spacing={10}>
      {posts.map((post) => (
        <Post post={post} key={post.id}></Post>
      ))}
    </VStack>
  );
};

export default Posts;
