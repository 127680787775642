import * as React from "react";
import { ChakraProvider, Box, Grid, theme, Text, Flex, extendTheme } from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import Posts from "./components/Posts/Posts";
import useSWR from "swr";
import { HOST_API } from "./constants";
import { fetcher } from "./utils/utils";
import { ReactElement } from "react";
import NewPost from "./components/NewPost/NewPost";

export const App = (): ReactElement => {
  const { data: posts, error } = useSWR(`${HOST_API}posts`, fetcher);
  
  const theme = extendTheme({
    config: {
      useSystemColorMode: true,
      initialColorMode: "dark"
    }
  });

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <Box w="50%" m="auto" justifyContent="center">
            <NewPost></NewPost>

            <Box mt={10}>
              {posts && <Posts posts={posts}></Posts>}

              {error && (
                <Text>
                  Failed to fetch posts! Please try again
                  {JSON.stringify(error)}
                </Text>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};
