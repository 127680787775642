import { Flex, Text } from "@chakra-ui/layout";
import axios from "axios";
import React, { useState } from "react";
import { ReactElement } from "react";
import { HOST_API, LOCALHOST_API } from "../../constants";
import { ReactionModel } from "../../models/Post.model";
import {
  ReactionService,
  REACTION_ACTION,
} from "../../services/Reaction.service";

const Reaction = ({
  postId,
  reaction,
}: {
  postId: string;
  reaction: ReactionModel;
}): ReactElement => {
  const [reactionCountState, setReactionCount] = useState(reaction.count);
  const reactToPost = () => {
    const reactionAction = ReactionService.reactionIncrementOrDecrement(
      postId,
      reaction
    );
    ReactionService.performReactionSideEffect(
      reactionAction,
      postId,
      reaction,
      setReactionCount
    );
    makeReactRequest(reactionAction);
  };

  const makeReactRequest = (reactionAction: boolean) => {
    axios
      .post(`${HOST_API}react`, {
        post_key: postId,
        reaction: reaction.reaction,
        action: reactionAction
          ? REACTION_ACTION.INCREMENT
          : REACTION_ACTION.DECREMENT,
      })
      .catch(function (error) {
        console.error(error);

        /** Handling error state by rolling back the reaction */
        ReactionService.performReactionSideEffect(
          !reactionAction,
          postId,
          reaction,
          setReactionCount
        );

        alert("Some error occurred while reacting");
      });
  };

  return (
    <Flex
      flexGrow={1}
      justifyContent="center"
      borderRadius="3px"
      cursor="pointer"
      css={{
        ":hover": {
          background: "#a4a4a450",
        },
      }}
      onClick={reactToPost}
    >
      <Text size="sm">{reaction.reaction}</Text>

      <Text size="sm" ml={2}>
        {reactionCountState}
      </Text>
    </Flex>
  );
};

export default Reaction;
