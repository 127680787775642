import { Image } from "@chakra-ui/image";
import { Flex, HStack, Text } from "@chakra-ui/layout";
import React from "react";
import { ReactElement } from "react";
import { ReactionModel } from "../../models/Post.model";
import Reaction from "../Reaction/Reaction";

const Reactions = ({
  postId,
  reactions,
}: {
  postId: string,
  reactions: ReactionModel[];
}): ReactElement => {
  return (
    <HStack margin="auto" spacing={2} justifyContent="space-evenly">
      {reactions.map((reaction, index) => (
        <Reaction postId={postId} reaction={reaction} key={index}></Reaction>
      ))}
    </HStack>
  );
};

export default Reactions;
