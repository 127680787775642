import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, VStack, Text } from "@chakra-ui/layout";
import { Formik } from "formik";
import React from "react";
import { ReactElement } from "react";
import { mutate } from "swr";
import { HOST_API } from "../../constants";

const NewPost = (): ReactElement => {
  const addPost = (values: { [key: string]: string }) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    };
    fetch(`${HOST_API}posts`, requestOptions)
      .then(() => {
        alert(`Post added successfully, It will reflect here shortly. Refresh page if it doesn't`);
        mutate(`${HOST_API}posts`);
      });
  };

  return (
    <Box p={5} border="2px solid grey" borderRadius="20px">
      <Text fontSize="2xl">New Post</Text>

      <Formik
        initialValues={{ username: "", title: "", content: "" }}
        onSubmit={(values, { setSubmitting }) => {
          addPost(values);
          setSubmitting(false);
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <VStack spacing={3}>
              <FormControl id="username" isRequired>
                <FormLabel>Username</FormLabel>
                <Input
                  onChange={handleChange}
                  value={values.username}
                  placeholder="Username"
                />
              </FormControl>

              <FormControl id="title" isRequired>
                <FormLabel>Title</FormLabel>
                <Input
                  onChange={handleChange}
                  value={values.title}
                  placeholder="Title"
                />
              </FormControl>

              <FormControl id="content" isRequired>
                <FormLabel>Content</FormLabel>
                <Input
                  onChange={handleChange}
                  value={values.content}
                  placeholder="Content"
                />
              </FormControl>

              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </VStack>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default NewPost;
