import { Box, Text } from "@chakra-ui/layout";
import axios from "axios";
import React, { ReactElement } from "react";
import { PostModel } from "../../models/Post.model";
import Reactions from "../Reactions/Reactions";

const Post = ({ post }: { post: PostModel }): ReactElement => {
  return (
    <Box
      w="100%"
      border="2px solid white"
      borderRadius="20px"
      p={8}
      pt={5}
      pb={3}
      align="left"
    >
      <Text fontSize="3xl" fontWeight={600}>
        {post.title}
      </Text>

      <Text fontSize="sm">By <Text as="i">{post.username}</Text></Text>

      <Text fontSize="md" mt={3} pt={3} borderTop="1px solid grey">
        {post.content}
      </Text>

      <Box mt={5}>
        <Reactions postId={post.id} reactions={post.reactions}></Reactions>
      </Box>
    </Box>
  );
};

export default Post;
